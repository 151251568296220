html,
body {
  height: 100%;
}
body {
  line-height: 28px;
  font-size: 16px;
  color: #666;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: 400;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 10px;
  padding: 0 0 0px;
  font-family: "Lora", serif;
  color: #333;
}
h1 {
  font-size: 50px;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 24px;
}
h4 {
  font-size: 18px;
}
h5 {
  font-size: 16px;
}
h6 {
  font-size: 15px;
}
p {
  font-size: 16px;
  margin: 0 0 15px;
  padding: 0 0 0px;
}
img {
  max-width: 100%;
}
a:hover {
  text-decoration: none;
}
.container-fluid.custom-container {
  padding-left: 8%;
  padding-right: 8%;
}
.btn-mr,
.btn-link,
button,
[type="button"],
[type="reset"],
[type="submit"],
.single-blog .blog-text .blog-link,
.call-to-action a,
.filter-list li,
.rationSinglePrice .priceFooter a {
  display: inline-block;
  vertical-align: middle;
  font-size: 16px;
  padding: 13px 40px;
  text-transform: capitalize;
  -webkit-transition: all 0.24s cubic-bezier(0.23, 1, 0.32, 1);
  transition: all 0.24s cubic-bezier(0.23, 1, 0.32, 1);
  line-height: 24px;
  font-weight: 500;
}
.btn-mr.pill,
.pill.btn-link,
button.pill,
.pill[type="button"],
.pill[type="reset"],
.pill[type="submit"],
.single-blog .blog-text .pill.blog-link,
.call-to-action a.pill,
.filter-list li.pill,
.rationSinglePrice .priceFooter a.pill {
  border-radius: 999px;
}
.btn-mr.xsbtn,
.xsbtn.btn-link,
button.xsbtn,
.xsbtn[type="button"],
.xsbtn[type="reset"],
.xsbtn[type="submit"],
.single-blog .blog-text .xsbtn.blog-link,
.call-to-action a.xsbtn,
.filter-list li.xsbtn,
.rationSinglePrice .priceFooter a.xsbtn {
  padding: 6px 20px;
  font-size: 14px;
}
@media (max-width: 767.98px) {
  .btn-mr,
  .btn-link,
  button,
  [type="button"],
  [type="reset"],
  [type="submit"],
  .single-blog .blog-text .blog-link,
  .call-to-action a,
  .filter-list li,
  .rationSinglePrice .priceFooter a {
    padding: 10px 25px;
    font-size: 14px;
  }
}
.th-primary {
  background: #90c438;
  color: #fff;
  border: 1px solid #90c438;
  border-radius: 3px;
}
.th-primary:hover {
  background: #82b032;
  border: 1px solid #82b032;
  color: #fff;
}
.th-primary:focus {
  outline: none;
  color: #fff;
}
.th-secondary {
  background: #f7941d;
  color: #fff;
  border: 1px solid #f7941d;
  border-radius: 3px;
}
.th-secondary:hover {
  background: #de851a;
  border: 1px solid #de851a;
  color: #fff;
}
.th-secondary:focus {
  outline: none;
  color: #fff;
}
.th-primary-outline {
  background: transparent;
  color: #333;
  border: 1px solid #90c438;
  border-radius: 3px;
}
.th-primary-outline:hover {
  background: #82b032;
  border: 1px solid #82b032;
  color: #fff;
}
.th-primary-outline:focus {
  outline: none;
  color: #fff;
}
.th-secondary-outline {
  background: transparent;
  color: #333;
  border: 1px solid #f7941d;
  border-radius: 3px;
}
.th-secondary-outline:hover {
  background: #de851a;
  border: 1px solid #de851a;
  color: #fff;
}
.th-secondary-outline:focus {
  outline: none;
  color: #fff;
}
.th-gradient,
.th-gradient2,
button,
[type="button"],
[type="reset"],
[type="submit"],
.filter-list li.active,
.filter-list li:hover {
  background: linear-gradient(120deg, #90c438 0%, #6ea117 100%);
  color: #fff;
  border-radius: 3px;
  position: relative;
  z-index: 1;
}
.th-gradient:after,
.th-gradient2:after,
button:after,
[type="button"]:after,
[type="reset"]:after,
[type="submit"]:after,
.filter-list li.active:after,
.filter-list li:hover:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  background: linear-gradient(-120deg, #90c438 0%, #6ea117 100%);
  opacity: 0;
  visibility: hidden;
  border-radius: 999px;
  -webkit-transition: all 0.36s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition: all 0.36s cubic-bezier(0.47, 0, 0.745, 0.715);
  color: #fff !important;
}
.th-gradient:hover,
.th-gradient2:hover,
button:hover,
:hover[type="button"],
:hover[type="reset"],
:hover[type="submit"],
.filter-list li:hover {
  color: #fff !important;
  -webkit-box-shadow: 0px 20px 25px 0px rgba(144, 196, 56, 0.15);
  box-shadow: 0px 20px 25px 0px rgba(144, 196, 56, 0.15);
}
.th-gradient:hover:after,
.th-gradient2:hover:after,
button:hover:after,
[type="button"]:hover:after,
[type="reset"]:hover:after,
[type="submit"]:hover:after,
.filter-list li:hover:after {
  opacity: 1;
  visibility: visible;
}
.th-gradient2 {
  background: linear-gradient(120deg, #f7941d 0%, #d67b0d 100%);
}
.th-gradient2:hover {
  -webkit-box-shadow: 0px 20px 25px 0px rgba(247, 148, 29, 0.15);
  box-shadow: 0px 20px 25px 0px rgba(247, 148, 29, 0.15);
}
.th-gradient2:after {
  background: linear-gradient(-120deg, #f7941d 0%, #d67b0d 100%);
}
.th-outline-gradient-primary {
  background: transparent;
  color: #333;
  border-radius: 3px;
  border: 1px solid #90c438;
  position: relative;
  z-index: 1;
}
.th-outline-gradient-primary:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  background: linear-gradient(-120deg, #90c438 0%, #6ea117 100%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.24s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition: all 0.24s cubic-bezier(0.47, 0, 0.745, 0.715);
  color: #333 !important;
  border-radius: 999px;
}
.th-outline-gradient-primary:hover {
  color: #fff !important;
  border: 1px solid transparent;
}
.th-outline-gradient-primary:hover:after {
  opacity: 1;
  visibility: visible;
}
.th-outline-gradient-secondary {
  background: transparent;
  color: #333;
  border-radius: 3px;
  border: 1px solid #eeeeee;
  position: relative;
  z-index: 1;
}
.th-outline-gradient-secondary:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  background: linear-gradient(-120deg, #90c438 0%, #6ea117 100%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.24s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition: all 0.24s cubic-bezier(0.47, 0, 0.745, 0.715);
  border-radius: 999px;
  color: #333 !important;
}
.th-outline-gradient-secondary:hover {
  color: #fff !important;
  border: 1px solid transparent;
}
.th-outline-gradient-secondary:hover:after {
  opacity: 1;
  visibility: visible;
}
.btn {
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
  border-radius: 0px;
  padding: 13px 40px;
  font-weight: 400;
  -webkit-transition: all 0.24s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: all 0.24s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.btn.pill {
  border-radius: 999px;
}
@media (max-width: 767.98px) {
  padding: 10px 25px;
  font-size: 14px;
}
.btn-link {
  padding: 0px;
  position: relative;
  font-weight: 500;
  color: #666;
}
.btn-link i {
  padding-left: 5px;
  -webkit-transition: all 0.24s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.24s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.btn-link:hover {
  text-decoration: none;
}
.btn-link:hover i {
  padding-left: 10px;
}
.btn-primary {
  color: #fff !important;
  background: #007bff;
  border-color: #007bff;
}
.btn-primary:hover {
  background: #006fe6;
  border-color: #006fe6;
}
.btn-secondary {
  color: #fff !important;
  background: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  background: #616971;
  border-color: #616971;
}
.btn-success {
  color: #fff !important;
  background: #28a745;
  border-color: #28a745;
}
.btn-success:hover {
  background: #24963e;
  border-color: #24963e;
}
.btn-danger {
  color: #fff !important;
  background: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  background: #c6303e;
  border-color: #c6303e;
}
.btn-warning {
  color: #fff !important;
  background: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  background: #e6ae06;
  border-color: #e6ae06;
}
.btn-info {
  color: #fff !important;
  background: #17a2b8;
  border-color: #17a2b8;
}
.btn-info:hover {
  background: #1592a6;
  border-color: #1592a6;
}
.btn-light {
  color: #666 !important;
  background: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  background: #ecedee;
  border-color: #ecedee;
}
.btn-dark {
  color: #fff !important;
  background: #343a40;
  border-color: #343a40;
}
.btn-dark:hover {
  background: #2f343a;
  border-color: #2f343a;
}
.section-padding {
  padding: 100px 0 70px;
}
.section-wrapper {
  margin-bottom: 60px;
}
.section-wrapper p {
  color: #666;
}
.section-wrapper.no-margin {
  margin-bottom: 0px;
}
.section-title {
  font-size: 36px;
  font-weight: 700;
  margin: 0 0 20px;
  text-transform: capitalize;
}
.section-title.xs {
  font-size: 30px;
}
.section-title.xs2 {
  font-size: 24px;
}
@media (max-width: 767.98px) {
  .section-title {
    font-size: 24px;
  }
  .section-title.xs {
    font-size: 22px;
  }
  .section-title.xs2 {
    font-size: 20px;
  }
}
[type="color"],
[type="date"],
[type="datetime"],
[type="datetime-local"],
[type="email"],
[type="month"],
[type="number"],
[type="password"],
[type="search"],
[type="tel"],
[type="text"],
[type="time"],
[type="url"],
[type="week"],
input:not([type]),
textarea {
  height: 45px;
  display: block;
  width: 100%;
  border: 1px solid #eee;
  border-radius: 3px;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
[type="color"]:active,
[type="date"]:active,
[type="datetime"]:active,
[type="datetime-local"]:active,
[type="email"]:active,
[type="month"]:active,
[type="number"]:active,
[type="password"]:active,
[type="search"]:active,
[type="tel"]:active,
[type="text"]:active,
[type="time"]:active,
[type="url"]:active,
[type="week"]:active,
input:not([type]):active,
textarea:active {
  border: 1px solid #90c438;
}
[type="color"]:focus,
[type="date"]:focus,
[type="datetime"]:focus,
[type="datetime-local"]:focus,
[type="email"]:focus,
[type="month"]:focus,
[type="number"]:focus,
[type="password"]:focus,
[type="search"]:focus,
[type="tel"]:focus,
[type="text"]:focus,
[type="time"]:focus,
[type="url"]:focus,
[type="week"]:focus,
input:not([type]):focus,
textarea:focus {
  outline: none;
  border: 1px solid #90c438;
}
button,
[type="button"],
[type="reset"],
[type="submit"] {
  border-radius: 999px;
  border: 1px solid #90c438;
}
.spacer-extra {
  height: 40px;
}
.spacer-extra2 {
  height: 80px;
}
.extr-spacer3 {
  height: 15px;
}
.alignnone {
  margin: 5px 20px 20px 0;
}
.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}
.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}
.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}
a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}
a img.alignnone {
  margin: 5px 20px 20px 0;
}
a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}
a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%;
  padding: 5px 3px 10px;
  text-align: center;
}
.wp-caption.alignnone,
.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}
.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}
.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}
.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}
.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  -webkit-clip-path: inset(50%);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}
.screen-reader-text:focus {
  background-color: #eee;
  clip: auto !important;
  -webkit-clip-path: none;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}
.top-header {
  padding: 10px 0 0;
}
.top-header ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.top-header ul li {
  display: inline-block;
}
.top-header ul li a {
  display: block;
}
.top-header ul.lft {
  margin-left: -5px;
}
.top-header ul.lft li {
  margin: 0 10px;
  color: #fff;
}
.top-header ul.lft li i {
  height: 25px;
  width: 25px;
  border: 1px solid #fff;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
}
.top-header ul.lft li a {
  color: #fff;
}
.top-header ul.lft li a i {
  height: 25px;
  width: 25px;
  border: 1px solid #fff;
  text-align: center;
  line-height: 25px;
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
  border-radius: 50%;
}
.top-header ul.rgt {
  margin-right: -5px;
}
.top-header ul.rgt li a {
  height: 30px;
  width: 30px;
  line-height: 30px;
  color: #cccccc;
  border: 1px solid #cccccc;
  text-align: center;
  border-radius: 50%;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.top-header ul.rgt li a:hover {
  background: #90c438;
  color: #fff;
  border: 1px solid #90c438;
}
.site-header.header-2 .top-header {
  background: #f6f5f5;
  padding: 10px 0;
}
.site-header.header-2 .top-header ul.lft li a,
.site-header.header-2 .top-header ul.lft li {
  color: #666;
}
.site-header.header-2 .top-header ul.lft li a i,
.site-header.header-2 .top-header ul.lft li i {
  border-color: #666;
}
.site-header {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}
.site-header .navbar {
  padding: 15px 0;
}
.site-header .navbar.sticky {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  width: 100%;
  background: linear-gradient(-120deg, #90c438 0%, #6ea117 100%);
  -webkit-box-shadow: 0px 25px 20px 0 rgba(144, 196, 56, 0.1);
  box-shadow: 0px 25px 20px 0 rgba(144, 196, 56, 0.1);
}
.site-header .navbar.sticky:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  z-index: -1;
  background: linear-gradient(120deg, #90c438 0%, #6ea117 100%);
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.36s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition: all 0.36s cubic-bezier(0.47, 0, 0.745, 0.715);
}
.site-header .navbar.sticky:hover:after {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  visibility: visible;
}
.site-header.header-2 .navbar.sticky {
  background: #fff;
  -webkit-box-shadow: 0px 25px 20px 0 rgba(102, 102, 102, 0.1);
  box-shadow: 0px 25px 20px 0 rgba(102, 102, 102, 0.1);
}
.site-header.header-2 .navbar.sticky:after {
  display: none;
}
#scrollUp {
  background: linear-gradient(-120deg, #90c438 0%, #6ea117 100%);
  height: 40px;
  width: 40px;
  line-height: 40px;
  right: 30px;
  bottom: 30px;
  border-radius: 50%;
  text-align: center;
  color: #fff;
  font-size: 30px;
}
.preloader-wrapper {
  background: #fffdff;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 99999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.preloader-wrapper .preloader-thumb {
  text-align: center;
}
.main-menu ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
  text-align: center;
  margin-right: -13px;
}
.main-menu ul li {
  display: inline-block;
  position: relative;
}
.main-menu ul li a {
  color: #444444;
  display: block;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  padding: 0px 13px;
  font-weight: 500;
  text-transform: capitalize;
  -webkit-transition: all 0.34s ease-in-out;
  transition: all 0.34s ease-in-out;
}
.main-menu ul li a:hover {
  text-decoration: none;
}
.main-menu a:foucs {
  outline: none;
  text-decoration: none;
}
.main-menu > ul > li.current-menu-item > a {
  color: #90c438;
}
.main-menu > ul > li:hover > a {
  color: #90c438;
}
.main-menu > ul > li.menu-item-has-children > a:after {
  content: "\f0d7";
  margin-left: 4px;
  font-family: "Fontawesome";
}
.main-menu > ul > li.menu-item-has-children > ul.sub-menu {
  position: absolute;
  left: 0;
  top: 120%;
  background: #90c438;
  z-index: 999;
  color: #333;
  text-align: left;
  min-width: 230px;
  -webkit-transition: all 0.42s ease-in-out;
  transition: all 0.42s ease-in-out;
  -webkit-box-shadow: 0px 10px 14px 0px rgba(249, 248, 247, 0.1);
  box-shadow: 0px 10px 14px 0px rgba(249, 248, 247, 0.1);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
}
.main-menu > ul > li.menu-item-has-children:hover > ul.sub-menu {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  visibility: visible;
  top: 100%;
}
.main-menu > ul > li.menu-item-has-children > ul.sub-menu > li,
.main-menu ul ul li {
  display: block;
}
.main-menu > ul > li.menu-item-has-children > ul.sub-menu > li > a,
.main-menu ul ul li a {
  display: block;
  padding: 8px 15px;
  border-bottom: 1px solid #96c742;
  text-transform: capitalize;
  color: #ffffff;
  font-weight: 400;
  position: relative;
  z-index: 1;
}
.main-menu > ul > li.menu-item-has-children > ul.sub-menu > li:last-child > a,
.main-menu ul ul li:last-child a {
  border-bottom: 1px solid transparent;
}
.main-menu > ul > li.menu-item-has-children > ul.sub-menu > li:hover > a,
.main-menu ul ul > li:hover > a {
  padding-left: 23px;
  color: #90c438;
  background: #ffffff;
}
.main-menu ul ul li.menu-item-has-children > a:after {
  content: "\f0da";
  position: absolute;
  font-family: "Fontawesome";
  right: 10px;
}
.main-menu ul ul li.menu-item-has-children.change-icon > a:after {
  content: "\f0da";
}
.main-menu ul ul {
  position: absolute;
  left: 232px;
  top: 30px;
  background: #90c438;
  z-index: 999;
  color: #333;
  text-align: left;
  min-width: 230px;
  -webkit-transition: all 0.42s ease-in-out;
  transition: all 0.42s ease-in-out;
  -webkit-box-shadow: 0px 10px 14px 0px rgba(249, 248, 247, 0.1);
  box-shadow: 0px 10px 14px 0px rgba(249, 248, 247, 0.1);
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
}
.main-menu
  > ul
  > li.menu-item-has-children
  > ul.sub-menu
  > li.menu-item-has-children:hover
  > ul.sub-menu,
.main-menu
  > ul
  > li.menu-item-has-children
  > ul.sub-menu
  > li.menu-item-has-children
  > ul.sub-menu
  > li.menu-item-has-children:hover
  > ul.sub-menu {
  top: 0px;
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  visibility: visible;
}
.mobile-menu {
  display: none;
}
.menu-click {
  background: linear-gradient(120deg, #90c438 0%, #6ea117 100%);
  border: 1px solid #90c438;
  cursor: pointer;
  display: inline-block;
  position: static;
  right: 10px;
  padding: 8px 7px 4px;
  top: -60px;
  -webkit-transition: all 0.34s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.34s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  z-index: 9999;
}
.menu-click span {
  -webkit-transition: all 0.34s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  transition: all 0.34s cubic-bezier(0.68, -0.55, 0.265, 1.55);
  width: 30px;
  background: #fff;
  height: 2px;
  margin-bottom: 4px;
  display: block;
}
.mobile-menu {
  display: none;
}
.site-header .navbar.sticky .main-menu > ul > li > a {
  color: #fff;
}
.site-header .navbar.sticky .main-menu > ul > li.current-menu-item > a,
.site-header .navbar.sticky .main-menu > ul > li:hover > a {
  color: #567521;
}
.site-header .navbar.sticky .menu-click {
  background: linear-gradient(120deg, #f7941d 0%, #f7941d 100%);
  border: 1px solid #f7941d;
}
.site-header.header-2 .navbar.sticky .main-menu > ul > li > a {
  color: #444;
}
.site-header.header-2 .navbar.sticky .main-menu > ul > li.current-menu-item > a,
.site-header.header-2 .navbar.sticky .main-menu > ul > li:hover > a {
  color: #90c438;
}
.site-header.header-2 .navbar.sticky .menu-click {
  background: linear-gradient(120deg, #90c438 0%, #90c438 100%);
  border: 1px solid #90c438;
}
@media only screen and (max-width: 1199.98px) {
  .main-menu > ul > li:hover:before,
  .main-menu > ul > li:hover:after,
  .main-menu > ul > li.current-menu-item:before,
  .main-menu > ul > li.current-menu-item:after {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    visibility: hidden;
  }
  .main-menu ul {
    text-align: left;
  }
  .main-menu ul li {
    display: block;
  }
  .main-menu ul li a {
    padding: 8px 20px;
  }
  .main-menu ul > li:hover > a {
    background: #f7941d;
  }
  .main-menu > ul > li.menu-item-has-children > ul.sub-menu {
    display: none;
    -webkit-transition: none;
    transition: none;
    position: static;
    background: #81b032;
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    visibility: visible;
    padding-left: 15px;
    border-top: 3px solid transparent;
  }
  .main-menu > ul > li.menu-item-has-children > ul.sub-menu > li:hover > a,
  .main-menu ul ul > li:hover > a {
    color: #fff;
  }
  .main-menu ul ul {
    display: none;
    -webkit-transition: none;
    transition: none;
    position: static;
    background: transparent;
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    visibility: visible;
    padding-left: 15px;
    background: #81b032;
    border-top: 3px solid transparent;
  }
  .main-menu > ul > li.menu-item-has-children > ul.sub-menu > li > a,
  .main-menu ul ul li a {
    display: block;
    padding: 10px 15px;
    border-bottom: 1px solid transparent;
    color: #fff;
    text-transform: capitalize;
    padding: 7px 15px;
  }
  .main-menu > ul > li.menu-item-has-children > ul.sub-menu > li:hover > a,
  .main-menu ul ul > li:hover > a {
    padding-left: 10px;
    background: #f7941d;
  }
  .main-menu > ul > li.menu-item-has-children > ul.sub-menu > li > a,
  .main-menu ul ul li a {
    padding: 10px 0px;
  }
  .main-menu {
    overflow-x: hidden;
  }
  .main-menu ul ul li.menu-item-has-children > a:after {
    right: 70px;
  }
  .main-menu ul {
    opacity: 0;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: alpha(opacity=0);
    visibility: hidden;
    position: absolute;
    left: 0;
    top: 120%;
    width: 100%;
    background: #90c438;
    -webkit-transition: all 0.34s ease-in-out;
    transition: all 0.34s ease-in-out;
    z-index: 999;
  }
  .main-menu ul.show {
    opacity: 1;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    filter: alpha(opacity=100);
    visibility: visible;
    top: 100%;
  }
  .site-header .navbar.sticky .main-menu ul.show {
    height: 300px;
    overflow-x: auto;
  }
  .site-header .navbar.sticky .main-menu ul.show::-webkit-scrollbar {
    background: #f7941d;
    width: 10px;
  }
  .mobile-menu {
    display: block;
    text-align: right;
  }
  .main-menu ul.show > li > a {
    color: #fff;
  }
  .main-menu > ul > li.menu-item-has-children > ul.sub-menu > li > a,
  .main-menu ul ul li a {
    padding: 7px 0;
  }
  .site-main-header .main-menu > ul {
    text-align: left;
    margin-right: 0px;
  }
  .site-main-header.transparent_menu .main-menu > ul > li > a:hover {
    color: #fff !important;
  }
  .site-header.sticky .main-menu > ul > li > a {
    color: #fff;
  }
}
.site-slider {
  height: 100vh;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1;
}
.site-slider:before {
  position: absolute;
  left: 0;
  top: 0;
  background-image: url(../public/images/shape-1.png);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  content: "";
  height: 378px;
  width: 947px;
  z-index: 99;
}
.bg-1 {
  background-image: url(../public/images/banner-1.jpg);
}
.bg-2 {
  background-image: url(../public/images/banner-2.jpg);
  background-position: top;
}
.single-slide {
  height: 100vh;
}
.bn_height {
  height: 100vh;
}
.slide-content h1 {
  font-size: 40px;
  text-transform: capitalize;
  font-weight: 700;
  line-height: 50px;
  margin: 0 0 20px;
}
.slide-content h1 span {
  display: block;
  color: #90c438;
}
.slide-content p {
  margin: 0 0 30px;
}
.slide-content a {
  margin: 0 5px;
}
.text-slide-active .owl-dots {
  position: absolute;
  left: 30%;
  -webkit-transform: translateX(-30%);
  transform: translateX(-30%);
  bottom: 40px;
  z-index: 999;
}
.text-slide-active .owl-dot {
  background: #ccc;
  height: 8px;
  width: 8px;
  display: inline-block;
  border: 1px solid #ccc;
  -webkit-transition: all 0.19s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition: all 0.19s cubic-bezier(0.47, 0, 0.745, 0.715);
  border-radius: 50%;
  margin: 0 5px;
}
.text-slide-active .owl-dot.active {
  background: #90c438;
  width: 10px;
  height: 10px;
  border: 1px solid #90c438;
}
.page-banner,
.video-promtionOX {
  background-image: url("../public/images/page-banner.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  height: 350px;
  position: relative;
  z-index: 1;
  margin-bottom: 50px;
}
.page-banner:after,
.video-promtionOX:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #fff;
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  z-index: -1;
}
.page-banner .page-bn-height,
.video-promtionOX .page-bn-height {
  height: 350px;
}
.page-banner h3,
.video-promtionOX h3 {
  font-size: 30px;
  text-transform: capitalize;
  font-weight: 700;
}
.breadcrumb.site-breadcumb-1 {
  background: transparent;
  padding: 0;
}
.breadcrumb.site-breadcumb-1 li a {
  color: #444;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.breadcrumb.site-breadcumb-1 li a:hover {
  color: #90c438;
}
.breadcrumb.site-breadcumb-1 li.active {
  color: #90c438;
}
.pst-gallery-active .owl-dots {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 15px;
  z-index: 999;
}
.single-gallery.pst-gallery-active .owl-dots {
  bottom: 40px;
}
.pst-gallery-active .owl-dot {
  background: #90c438;
  height: 8px;
  width: 8px;
  display: inline-block;
  border: 1px solid #90c438;
  -webkit-transition: all 0.19s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition: all 0.19s cubic-bezier(0.47, 0, 0.745, 0.715);
  border-radius: 50%;
  margin: 0 5px;
}
.page-banner h3,
.video-promtionOX h3 {
  padding-top: 130px;
}
.health-guide {
  padding: 100px 0 40px;
}
.health-guide.home-page2 {
  background: #fafafa;
}
.single-health-block {
  background: #f9f9f9;
  min-height: 325px;
  margin-bottom: 70px;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
  padding: 15px;
  position: relative;
}
.single-health-block .icon-box {
  position: absolute;
  left: 50%;
  top: -40px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  height: 80px;
  width: 80px;
  line-height: 80px;
  font-size: 36px;
  border-radius: 50%;
  color: #444;
  background: #ebe8e8;
  -webkit-box-shadow: 0 0 0 10px #f9f9f9;
  box-shadow: 0 0 0 10px #f9f9f9;
}
.single-health-block h4 {
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 700;
  margin: 40px 0 10px;
}
.single-health-block p {
  color: #444;
  margin: 0 0 0;
}
.single-health-block:hover {
  background: #fff;
  -webkit-box-shadow: 0px 25px 20px 0 rgba(136, 136, 136, 0.1);
  box-shadow: 0px 25px 20px 0 rgba(136, 136, 136, 0.1);
}
.single-health-block:hover .icon-box {
  background: #90c438;
  -webkit-box-shadow: 0 0 0 10px #ecfdca;
  box-shadow: 0 0 0 10px #ecfdca;
  color: #fff;
}
.boxicon {
  position: relative;
  width: 80px;
  height: 46.19px;
  background-color: #eee;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
  margin: 23.09px 0;
}
.boxicon:before,
.boxicon:after {
  content: "";
  position: absolute;
  width: 0;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.boxicon:before {
  bottom: 100%;
  border-bottom: 23.09px solid #eee;
}
.boxicon:after {
  top: 100%;
  width: 0;
  border-top: 23.09px solid #eee;
  left: 0;
}
.singleHelthBlock {
  margin-bottom: 30px;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
  padding: 15px;
  border-radius: 5px;
}
.singleHelthBlock .boxicon i {
  font-size: 35px;
  text-align: center;
  display: block;
  line-height: 50px;
  border: 1px dashed #3c3c3c;
  color: #3c3c3c;
  height: 50px;
  width: 50px;
  border-radius: 42px;
  margin: -2px auto;
  position: relative;
  z-index: 2;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.singleHelthBlock h4 {
  font-size: 20px;
  text-transform: capitalize;
  font-weight: 700;
  color: #333;
}
.singleHelthBlock h4 a {
  color: #333;
  font-size: 20px;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.singleHelthBlock a {
  color: #666;
  font-size: 14px;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.singleHelthBlock p {
  margin: 0 0 5px;
}
.singleHelthBlock:hover {
  background: #fff;
  -webkit-box-shadow: 0px 25px 20px 0 rgba(136, 136, 136, 0.1);
  box-shadow: 0px 25px 20px 0 rgba(136, 136, 136, 0.1);
}
.singleHelthBlock:hover .boxicon {
  background: #90c438;
  -ms-flex-item-align: center;
  align-self: center;
  -webkit-animation: fadeInDown 400ms ease-in-out;
  animation: fadeInDown 400ms ease-in-out;
}
.singleHelthBlock:hover .boxicon:before {
  border-bottom: 23.09px solid #90c438;
}
.singleHelthBlock:hover .boxicon:after {
  border-top: 23.09px solid #90c438;
}
.singleHelthBlock:hover .boxicon i {
  color: #fff;
  border: 1px dashed transparent;
}
.singleHelthBlock:hover h4 a {
  color: #90c438;
}
.singleHelthBlock:hover a {
  color: #90c438;
}
.single-health-block-big {
  margin-bottom: 100px;
}
.helty-recipe {
  background-color: #fcfcfc;
  padding: 100px 0 50px;
}
.helty-recipe.home-page2 {
  background-color: #fff;
}
.healty_recipe_active .owl-item {
  padding-bottom: 50px;
  padding-top: 50px;
}
.healty_recipe_active .owl-item img {
  width: auto;
}
.single-helathy-recipe {
  background: #fff;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(178, 178, 178, 0.15);
  box-shadow: 0px 5px 10px 0px rgba(178, 178, 178, 0.15);
  padding-left: 30px;
  min-height: 300px;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
  border-radius: 5px;
}
.single-helathy-recipe h3 {
  text-transform: capitalize;
  font-weight: 500;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.single-helathy-recipe p {
  margin: 0 0 8px;
}
.single-helathy-recipe address strong {
  color: #333;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.single-helathy-recipe address a {
  color: #666;
  font-size: 14px;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.single-helathy-recipe address a:hover {
  color: #90c438;
}
.single-helathy-recipe:hover {
  -webkit-box-shadow: 0px 25px 20px 0 rgba(136, 136, 136, 0.1);
  box-shadow: 0px 25px 20px 0 rgba(136, 136, 136, 0.1);
}
.single-helathy-recipe:hover h3 {
  color: #90c438;
}
.single-helathy-recipe:hover address strong {
  color: #90c438;
}
.single-helathy-recipe:hover .img-thumbnail {
  border-color: #90c438;
}
.helty-recipe.home-page2 .single-helathy-recipe {
  border: 1px solid #f1f1f1;
}
.healty_recipe_active .owl-dots,
.related-services-active .owl-dots {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  bottom: 0;
  z-index: 999;
}
.healty_recipe_active .owl-dot,
.related-services-active .owl-dot {
  background: #ccc;
  height: 8px;
  width: 8px;
  display: inline-block;
  border: 1px solid #ccc;
  -webkit-transition: all 0.19s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition: all 0.19s cubic-bezier(0.47, 0, 0.745, 0.715);
  border-radius: 50%;
  margin: 0 5px;
}
.healty_recipe_active .owl-dot.active,
.related-services-active .owl-dot.active {
  background: #90c438;
  width: 10px;
  height: 10px;
  border: 1px solid #90c438;
}
.related-services-active .owl-item {
  padding: 30px 0 40px;
}
.related-services-active .owl-item .singleHelthBlock {
  margin-bottom: 0px;
}
.single-experts {
  position: relative;
  height: 350px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.single-experts .experts-data {
  background: #90c438;
  -webkit-box-shadow: 0px 20px 40px 0px rgba(106, 136, 52, 0.2);
  box-shadow: 0px 20px 40px 0px rgba(106, 136, 52, 0.2);
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 10px 0;
}
.single-experts .experts-data ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.single-experts .experts-data ul li {
  display: inline-block;
}
.single-experts .experts-data ul li a {
  display: block;
  color: #fff;
  margin: 0 5px;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.single-experts .experts-data ul li a:hover {
  color: #f7941d;
}
.experts-detils h4 {
  font-size: 24px;
  color: #90c438;
  margin: 0 0 8px;
  font-weight: 700;
  text-transform: capitalize;
}
.experts-detils span {
  display: block;
  margin: 0 0 10px;
}
.custom-tabs1 {
  margin-bottom: 30px;
}
.custom-tabs1 li.nav-item {
  margin: 0 2px;
}
.custom-tabs1 li.nav-item a.nav-link {
  background: #eeeeee;
  color: #444;
  font-size: 18px;
  font-weight: bold;
}
.custom-tabs1 li.nav-item a.nav-link.active {
  background: #90c438;
  color: #fff;
}
.custom-tabs1 li.nav-item:nth-child(1) a.nav-link {
  border-radius: 999px 0 0 999px;
}
.custom-tabs1 li.nav-item:nth-child(2) a.nav-link {
  border-radius: 0px 999px 999px 0;
}
.tab-content .media img {
  -webkit-animation: fadeInUp 400ms ease-in-out;
  animation: fadeInUp 400ms ease-in-out;
}
.tab-content .media p {
  -webkit-animation: fadeInDown 400ms ease-in-out;
  animation: fadeInDown 400ms ease-in-out;
}
.exparts-active .owl-dots {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%);
  transform: translate(-50%);
  bottom: -40px;
  z-index: 999;
}
.exparts-active .owl-dot {
  background: #ccc;
  height: 8px;
  width: 8px;
  display: inline-block;
  border: 1px solid #ccc;
  -webkit-transition: all 0.19s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition: all 0.19s cubic-bezier(0.47, 0, 0.745, 0.715);
  border-radius: 50%;
  margin: 0 5px;
}
.exparts-active .owl-dot.active {
  background: #90c438;
  width: 10px;
  height: 10px;
  border: 1px solid #90c438;
}
.appoinment-area {
  position: relative;
  margin: 50px 0 100px;
}
.appoinment-box {
  -webkit-box-shadow: 0px 25px 20px 0 rgba(136, 136, 136, 0.15);
  box-shadow: 0px 25px 20px 0 rgba(136, 136, 136, 0.15);
  padding: 50px 0px 70px 100px;
  position: relative;
  z-index: 3;
  top: 50px;
  background: #fff;
}
.appoinment-box [type="color"],
.appoinment-box [type="date"],
.appoinment-box [type="datetime"],
.appoinment-box [type="datetime-local"],
.appoinment-box [type="email"],
.appoinment-box [type="month"],
.appoinment-box [type="number"],
.appoinment-box [type="password"],
.appoinment-box [type="search"],
.appoinment-box [type="tel"],
.appoinment-box [type="text"],
.appoinment-box [type="time"],
.appoinment-box [type="url"],
.appoinment-box [type="week"],
.appoinment-box input:not([type]),
.appoinment-box textarea {
  border: 1px solid transparent;
  border-bottom: 1px solid #eee;
}
.appoinment-box [type="color"]::-webkit-input-placeholder,
.appoinment-box [type="date"]::-webkit-input-placeholder,
.appoinment-box [type="datetime"]::-webkit-input-placeholder,
.appoinment-box [type="datetime-local"]::-webkit-input-placeholder,
.appoinment-box [type="email"]::-webkit-input-placeholder,
.appoinment-box [type="month"]::-webkit-input-placeholder,
.appoinment-box [type="number"]::-webkit-input-placeholder,
.appoinment-box [type="password"]::-webkit-input-placeholder,
.appoinment-box [type="search"]::-webkit-input-placeholder,
.appoinment-box [type="tel"]::-webkit-input-placeholder,
.appoinment-box [type="text"]::-webkit-input-placeholder,
.appoinment-box [type="time"]::-webkit-input-placeholder,
.appoinment-box [type="url"]::-webkit-input-placeholder,
.appoinment-box [type="week"]::-webkit-input-placeholder,
.appoinment-box input:not([type])::-webkit-input-placeholder,
.appoinment-box textarea::-webkit-input-placeholder {
  color: #ccc;
  text-transform: capitalize;
}
.appoinment-box [type="color"]:-ms-input-placeholder,
.appoinment-box [type="date"]:-ms-input-placeholder,
.appoinment-box [type="datetime"]:-ms-input-placeholder,
.appoinment-box [type="datetime-local"]:-ms-input-placeholder,
.appoinment-box [type="email"]:-ms-input-placeholder,
.appoinment-box [type="month"]:-ms-input-placeholder,
.appoinment-box [type="number"]:-ms-input-placeholder,
.appoinment-box [type="password"]:-ms-input-placeholder,
.appoinment-box [type="search"]:-ms-input-placeholder,
.appoinment-box [type="tel"]:-ms-input-placeholder,
.appoinment-box [type="text"]:-ms-input-placeholder,
.appoinment-box [type="time"]:-ms-input-placeholder,
.appoinment-box [type="url"]:-ms-input-placeholder,
.appoinment-box [type="week"]:-ms-input-placeholder,
.appoinment-box input:not([type]):-ms-input-placeholder,
.appoinment-box textarea:-ms-input-placeholder {
  color: #ccc;
  text-transform: capitalize;
}
.appoinment-box [type="color"]::-ms-input-placeholder,
.appoinment-box [type="date"]::-ms-input-placeholder,
.appoinment-box [type="datetime"]::-ms-input-placeholder,
.appoinment-box [type="datetime-local"]::-ms-input-placeholder,
.appoinment-box [type="email"]::-ms-input-placeholder,
.appoinment-box [type="month"]::-ms-input-placeholder,
.appoinment-box [type="number"]::-ms-input-placeholder,
.appoinment-box [type="password"]::-ms-input-placeholder,
.appoinment-box [type="search"]::-ms-input-placeholder,
.appoinment-box [type="tel"]::-ms-input-placeholder,
.appoinment-box [type="text"]::-ms-input-placeholder,
.appoinment-box [type="time"]::-ms-input-placeholder,
.appoinment-box [type="url"]::-ms-input-placeholder,
.appoinment-box [type="week"]::-ms-input-placeholder,
.appoinment-box input:not([type])::-ms-input-placeholder,
.appoinment-box textarea::-ms-input-placeholder {
  color: #ccc;
  text-transform: capitalize;
}
.appoinment-box [type="color"]::placeholder,
.appoinment-box [type="date"]::placeholder,
.appoinment-box [type="datetime"]::placeholder,
.appoinment-box [type="datetime-local"]::placeholder,
.appoinment-box [type="email"]::placeholder,
.appoinment-box [type="month"]::placeholder,
.appoinment-box [type="number"]::placeholder,
.appoinment-box [type="password"]::placeholder,
.appoinment-box [type="search"]::placeholder,
.appoinment-box [type="tel"]::placeholder,
.appoinment-box [type="text"]::placeholder,
.appoinment-box [type="time"]::placeholder,
.appoinment-box [type="url"]::placeholder,
.appoinment-box [type="week"]::placeholder,
.appoinment-box input:not([type])::placeholder,
.appoinment-box textarea::placeholder {
  color: #ccc;
  text-transform: capitalize;
}
.appoinment-box [type="color"]:focus,
.appoinment-box [type="date"]:focus,
.appoinment-box [type="datetime"]:focus,
.appoinment-box [type="datetime-local"]:focus,
.appoinment-box [type="email"]:focus,
.appoinment-box [type="month"]:focus,
.appoinment-box [type="number"]:focus,
.appoinment-box [type="password"]:focus,
.appoinment-box [type="search"]:focus,
.appoinment-box [type="tel"]:focus,
.appoinment-box [type="text"]:focus,
.appoinment-box [type="time"]:focus,
.appoinment-box [type="url"]:focus,
.appoinment-box [type="week"]:focus,
.appoinment-box input:not([type]):focus,
.appoinment-box textarea:focus {
  border: 1px solid transparent;
  border-bottom: 1px solid #90c438;
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
}
.appoinment-box button,
.appoinment-box [type="button"],
.appoinment-box [type="reset"],
.appoinment-box [type="submit"] {
  margin-top: 30px;
}
.appoinment-box .form-row > .col,
.appoinment-box .form-row > [class*="col-"] {
  padding-right: 15px;
  padding-left: 15px;
}
.appoinment-box .input-group-text {
  background: transparent;
  border: transparent;
  border-bottom: 1px solid #eee;
  color: #ccc;
}
.videobgplay {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  left: 0;
  top: 0;
  width: 50%;
  height: 608px;
  z-index: 2;
}
.videobgplay.bg_1 {
  background-image: url(../public/images/videobg-1.jpg);
}
.videobgplay.bg_2 {
  background-image: url(../public/images/videobg-2.jpg);
}
.videobgplay:after {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#90c438),
    color-stop(42%, rgba(200, 226, 156, 0.58)),
    color-stop(100%, rgba(255, 255, 255, 0)),
    to(#fff)
  );
  background-image: linear-gradient(
    360deg,
    #90c438 0%,
    rgba(200, 226, 156, 0.58) 42%,
    rgba(255, 255, 255, 0) 100%,
    #fff 100%
  );
  opacity: 0.63;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=63)";
  filter: alpha(opacity=63);
}
.frmdesign-1 {
  width: 65%;
  position: relative;
}
.appoinmentshpae-1,
.appoinmentshpae-2 {
  height: 216px;
  width: 216px;
  border-radius: 50%;
  background: #e2f6c0;
  position: absolute;
  right: 50px;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
  top: 15%;
}
.appoinmentshpae-1 .innershape,
.appoinmentshpae-2 .innershape {
  height: 86px;
  width: 86px;
  background: #ffdaae;
  border-radius: 50%;
  right: 0;
  bottom: 0;
  position: absolute;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.appoinmentshpae-2 {
  width: 40px;
  height: 40px;
  top: auto;
  bottom: 10px;
  background: #ffdaae;
  -webkit-transition: all 0.24s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: all 0.24s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  right: 0;
}
.appoinmentshpae-2 .innershape {
  height: 15px;
  width: 15px;
  background: #e2f6c0;
  -webkit-transition: all 0.24s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: all 0.24s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.appoinment-area:hover .appoinmentshpae-1,
.appoinment-area:hover .appoinmentshpae-2 {
  background: #ffdaae;
}
.appoinment-area:hover .appoinmentshpae-1 .innershape,
.appoinment-area:hover .appoinmentshpae-2 .innershape {
  background: #e2f6c0;
}
.appoinment-area:hover .appoinmentshpae-2 {
  background: #e2f6c0;
}
.appoinment-area:hover .appoinmentshpae-2 .innershape {
  background: #ffdaae;
}
.blog-area {
  padding: 50px 0 70px;
}
.blog-area.page-blog {
  padding: 100px 0 80px;
}
.single-blog {
  position: relative;
  margin-bottom: 30px;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(178, 178, 178, 0.15);
  box-shadow: 0px 5px 10px 0px rgba(178, 178, 178, 0.15);
}
.single-blog .blog-thumb {
  overflow: hidden;
  position: relative;
  z-index: 1;
}
.single-blog .blog-thumb:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    from(#90c438),
    color-stop(42%, rgba(200, 226, 156, 0.58)),
    color-stop(100%, rgba(255, 255, 255, 0)),
    to(#fff)
  );
  background-image: linear-gradient(
    360deg,
    #90c438 0%,
    rgba(200, 226, 156, 0.58) 42%,
    rgba(255, 255, 255, 0) 100%,
    #fff 100%
  );
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
}
.single-blog .blog-thumb img {
  -webkit-transition: all 0.64s ease-in-out;
  transition: all 0.64s ease-in-out;
}
.single-blog .blog-text {
  background: #fafafa;
  padding: 15px 30px;
}
.single-blog .blog-text ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.single-blog .blog-text ul li a {
  color: #444;
}
.single-blog .blog-text ul li a i {
  padding-right: 5px;
}
.single-blog .blog-text h4 {
  font-size: 23px;
  line-height: 30px;
  font-weight: 700;
  margin: 20px 0 20px;
  text-transform: capitalize;
}
.single-blog .blog-text h4 a {
  color: #333;
}
.single-blog .blog-text p {
  margin: 0 0 20px;
  font-size: 14px;
}
.single-blog .blog-text .blog-link {
  background: #efefef;
  color: #6b6b6b;
  border-radius: 999px;
  padding: 10px 30px;
  margin-bottom: 10px;
}
.single-blog .blog-text .blog-link:hover {
  background: #90c438;
  border: 1px solid #90c438;
  color: #fff;
  -webkit-box-shadow: 0px 20px 25px 0px rgba(144, 196, 56, 0.15);
  box-shadow: 0px 20px 25px 0px rgba(144, 196, 56, 0.15);
}
.single-blog:hover {
  -webkit-box-shadow: 0px 25px 20px 0 rgba(136, 136, 136, 0.1);
  box-shadow: 0px 25px 20px 0 rgba(136, 136, 136, 0.1);
}
.single-blog:hover .blog-thumb:after {
  opacity: 0.63;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=63)";
  filter: alpha(opacity=63);
  visibility: visible;
  -webkit-animation: slideInUp 0.24s ease-in-out;
  animation: slideInUp 0.24s ease-in-out;
}
.single-blog:hover .blog-thumb img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.single-blog.standard .post-meta,
.single-blog.gallery .post-meta,
.single-blog.sticky .post-meta {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  margin: 0;
  list-style: none;
  padding: 15px 30px;
  z-index: 4;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
}
.single-blog.standard .post-meta li a,
.single-blog.gallery .post-meta li a,
.single-blog.sticky .post-meta li a {
  color: #90c438;
}
.single-blog.standard a,
.single-blog.gallery a,
.single-blog.sticky a {
  color: #90c438;
}
.single-blog.standard:hover .post-meta,
.single-blog.gallery:hover .post-meta,
.single-blog.sticky:hover .post-meta {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  visibility: visible;
  -webkit-animation: fadeInUp 0.24s cubic-bezier(0.47, 0, 0.745, 0.715);
  animation: fadeInUp 0.24s cubic-bezier(0.47, 0, 0.745, 0.715);
}
.single-blog.standard:hover .post-meta li a,
.single-blog.gallery:hover .post-meta li a,
.single-blog.sticky:hover .post-meta li a {
  color: #fff;
}
.single-blog.qute .blog-text p,
.single-blog.link .blog-text p,
.single-blog.qute .blog-text h4,
.single-blog.link .blog-text h4 {
  font-size: 23px;
  line-height: 40px;
  color: #333;
  font-weight: 700;
  font-family: "Lora", serif;
  margin: 30px 0 30px;
  text-transform: capitalize;
}
.single-blog.qute .blog-text .quation i,
.single-blog.link .blog-text .quation i {
  font-size: 40px;
  color: #777;
}
.single-blog.link a {
  color: #90c438;
  text-decoration: underline;
}
.single-blog.sticky .sticky-box {
  position: absolute;
  left: 15px;
  top: 15px;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 35px;
  background: #90c438;
  -webkit-clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
  clip-path: polygon(50% 0%, 100% 50%, 50% 100%, 0% 50%);
}
.single-blog.sticky .sticky-box i {
  color: #fff;
}
.single-sidebar {
  min-height: auto !important;
  margin-bottom: 30px;
  padding: 15px;
}
.single-sidebar .sideabrHeader {
  margin-left: -15px;
  margin-right: -15px;
  margin-top: -15px;
}
.single-sidebar h5 {
  text-transform: capitalize;
}
.single-sidebar h5 a {
  color: #444;
}
.single-sidebar span a {
  color: #90c438;
}
.single-sidebar ul li a {
  color: #666;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
  position: relative;
}
.single-sidebar ul li a:after {
  position: absolute;
  left: -10px;
  content: "\f105";
  font-family: "Fontawesome";
}
.single-sidebar ul li:last-child {
  padding-bottom: 0px;
}
.single-sidebar ul li:last-child:hover {
  color: #90c438;
  padding-left: 5px;
}
.single-sidebar .gallery {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.single-sidebar .gallery-item {
  -webkit-box-flex: 31.33%;
  -ms-flex: 31.33%;
  flex: 31.33%;
  max-width: 31.33%;
  margin: 2px;
}
.single-sidebar .btn-mr,
.single-sidebar .btn-link,
.single-sidebar button,
.single-sidebar [type="button"],
.single-sidebar [type="reset"],
.single-sidebar [type="submit"],
.single-sidebar .single-blog .blog-text .blog-link,
.single-blog .blog-text .single-sidebar .blog-link,
.single-sidebar .call-to-action a,
.call-to-action .single-sidebar a,
.single-sidebar .filter-list li,
.filter-list .single-sidebar li,
.single-sidebar .rationSinglePrice .priceFooter a,
.rationSinglePrice .priceFooter .single-sidebar a {
  margin: 3px;
}
.single-blog-detials img {
  margin-bottom: 30px;
}
.single-blog-detials ul {
  margin: 0;
  padding: 0;
  list-style: none;
  margin-bottom: 20px;
}
.single-blog-detials ul li a {
  color: #444;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.single-blog-detials ul li a:hover {
  color: #90c438;
}
.single-blog-detials h2 {
  font-weight: 700;
  margin: 0 0 20px;
}
.single-blog-detials .blockquote {
  background: #f7f7f7;
  padding: 15px 20px;
  border-left: 4px solid #90c438;
  font-style: italic;
  font-size: 16px;
  color: #666666;
  font-family: "Roboto", sans-serif;
  position: relative;
  z-index: 1;
}
.single-blog-detials .blockquote:after {
  position: absolute;
  left: 20px;
  top: 10px;
  content: "\f10d";
  font-family: "Fontawesome";
  color: #dddddd;
  font-size: 40px;
  z-index: -1;
}
.comments-section .media.single-comments h5 {
  font-size: 18px;
  font-weight: 700;
  font-family: "Roboto", sans-serif;
}
.comments-section .media.single-comments h5 span {
  font-weight: 300;
  display: inline-block;
  font-size: 14px;
  padding-left: 70px;
}
.comments-section .media.single-comments .likeunlikeBox {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: right;
}
.comments-section .media.single-comments .likeunlikeBox li {
  display: inline-block;
}
.comments-section .media.single-comments .likeunlikeBox li a {
  display: block;
  margin: 0 5px;
  color: #666;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.comments-section .media.single-comments .likeunlikeBox li a:hover {
  color: #90c438;
}
.call-to-action {
  background: #70be2b;
  color: #fff;
  padding: 50px 0;
}
.call-to-action h3 {
  font-size: 30px;
  text-transform: capitalize;
  font-weight: 700;
  color: #fff;
}
.call-to-action p {
  margin: 0 0 0;
}
.call-to-action a {
  background: #fff;
  border-radius: 999px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #333;
  font-weight: 700;
  -webkit-box-shadow: 0px 25px 30px 0px rgba(144, 196, 56, 0.15);
  box-shadow: 0px 25px 30px 0px rgba(144, 196, 56, 0.15);
  padding: 15px 40px;
  text-transform: uppercase;
}
.call-to-action a:hover {
  color: #fff;
  background: #f7941d;
}
.promotion-area {
  padding: 100px 0;
  position: relative;
}
.promotion-area .appoinmentshpae-2 {
  right: auto;
  left: 100px;
  width: 100px;
  height: 100px;
}
.promotion-area .appoinmentshpae-2 .innershape {
  height: 40px;
  width: 40px;
}
.promotion-area:hover .appoinmentshpae-1,
.promotion-area:hover .appoinmentshpae-2 {
  background: #ffdaae;
}
.promotion-area:hover .appoinmentshpae-1 .innershape,
.promotion-area:hover .appoinmentshpae-2 .innershape {
  background: #e2f6c0;
}
.promotion-area:hover .appoinmentshpae-2 {
  background: #e2f6c0;
}
.promotion-area:hover .appoinmentshpae-2 .innershape {
  background: #ffdaae;
}
.single-cosunter-block {
  background: #ffffff;
  -webkit-box-shadow: 0px 5px 10px 0px rgba(144, 196, 56, 0.15);
  box-shadow: 0px 5px 10px 0px rgba(144, 196, 56, 0.15);
  border-radius: 3px;
  margin-bottom: 30px;
  padding: 30px 0 30px 30px;
  -webkit-transition: all 0.24s cubic-bezier(0.6, -0.28, 0.735, 0.045);
  transition: all 0.24s cubic-bezier(0.6, -0.28, 0.735, 0.045);
}
.single-cosunter-block:hover {
  -webkit-box-shadow: 0px 20px 25px 0px rgba(144, 196, 56, 0.15);
  box-shadow: 0px 20px 25px 0px rgba(144, 196, 56, 0.15);
}
.single-cosunter-block h5 {
  font-family: "Roboto", sans-serif;
  font-size: 25px;
  font-weight: 700;
  margin: 0 0 0;
}
.single-cosunter-block p {
  font-size: 14px;
  margin: 0 0 0;
}
.single-gall {
  height: 260px;
  margin-bottom: 6px;
  position: relative;
  z-index: 1;
  overflow: hidden;
}
.single-gall .gallery-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-transition: all 0.64s ease-in-out;
  transition: all 0.64s ease-in-out;
}
.single-gall .gallery-bg:before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(144, 196, 56, 0);
  content: "";
  z-index: -1;
  visibility: hidden;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.gallery-hover {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.gallery-hover a {
  height: 35px;
  width: 35px;
  border: 1px solid #fff;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  line-height: 35px;
  margin: 0 5px;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
}
.gallery-hover a:hover {
  background: #fff;
  border: 1px solid #fff;
  color: #90c438;
}
.single-gall:hover .gallery-bg {
  -webkit-transform: scale(1.3);
  transform: scale(1.3);
}
.single-gall:hover .gallery-bg:before {
  background: rgba(144, 196, 56, 0.7);
  visibility: visible;
  -webkit-animation: zoomIn 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
  animation: zoomIn 400ms cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.single-gall:hover .gallery-hover a {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  visibility: visible;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}
.single-gall:hover .gallery-hover a:nth-child(1) {
  -webkit-animation: fadeInUp 400ms ease-in-out;
  animation: fadeInUp 400ms ease-in-out;
}
.single-gall:hover .gallery-hover a:nth-child(2) {
  -webkit-animation: fadeInDown 400ms ease-in-out;
  animation: fadeInDown 400ms ease-in-out;
}
.galgird.row.grid > [class*="col-"] {
  padding-left: 3px !important;
  padding-right: 3px !important;
}
button.mfp-close {
  background: #fff;
  color: #90c438 !important;
  opacity: 1;
  font-size: 20px;
  height: 35px;
  width: 35px !important;
  border-radius: 50%;
  text-align: center !important;
  line-height: 35px !important;
  padding: 0 !important;
  position: absolute;
  top: -52px !important;
}
img.mfp-img {
  padding: 0px !important;
  border: 6px solid #fff;
  -webkit-box-shadow: 0px 25px 20px 0 rgba(136, 136, 136, 0.35) !important;
  box-shadow: 0px 25px 20px 0 rgba(136, 136, 136, 0.35) !important;
}
.mfp-counter {
  right: 15px;
  color: #fff;
  font-size: 14px;
  background: #f7941d;
  padding: 3px 16px;
  border-radius: 3px;
  font-weight: 700;
}
.mfp-content {
  border: 6px solid #fff;
  -webkit-box-shadow: 0px 25px 20px 0 rgba(136, 136, 136, 0.35) !important;
  box-shadow: 0px 25px 20px 0 rgba(136, 136, 136, 0.35) !important;
}
.gallerypage {
  overflow: hidden;
}
.gallerypage .single-gall {
  float: left;
  width: 25%;
  overflow: hidden;
  margin-bottom: 0px;
}
.filter-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.filter-list li {
  border: 1px solid #eeeeee;
  border-radius: 999px;
  margin: 0 5px;
  cursor: pointer;
}
.filter-list li.active,
.filter-list li:hover {
  border-radius: 999px;
}
.client-minithumb-active {
  margin-top: 80px;
}
.client-minithumb-active .single-mini-thumb {
  margin-bottom: 15px;
}
.review_text-active {
  padding: 40px 0;
}
.review_text-active .single-review-text {
  border: 1px solid #eee;
  padding: 30px;
  height: 230px;
}
.review_text-active .single-review-text .client-large-thumb {
  margin-bottom: 40px;
}
.review_text-active .single-review-text .client-large-thumb img {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  top: -60px;
}
.review_text-active .single-review-text h4 {
  text-transform: capitalize;
  color: #90c438;
  font-weight: 700;
  margin: 0 0 5px;
}
.review_text-active .single-review-text span {
  font-weight: 300;
}
.review_text-active .slick-list {
  padding: 60px 0;
}
.bmi-calcalutaer h4 {
  display: block;
  padding: 10px 20px;
  border-radius: 5px;
  margin-bottom: 30px;
  background: #90c438;
  color: #fff;
  text-transform: capitalize;
}
.bmi-calcalutaer label {
  font-weight: 700;
  color: #333333;
  font-size: 16px;
}
.bmi-calcalutaer [type="color"],
.bmi-calcalutaer [type="date"],
.bmi-calcalutaer [type="datetime"],
.bmi-calcalutaer [type="datetime-local"],
.bmi-calcalutaer [type="email"],
.bmi-calcalutaer [type="month"],
.bmi-calcalutaer [type="number"],
.bmi-calcalutaer [type="password"],
.bmi-calcalutaer [type="search"],
.bmi-calcalutaer [type="tel"],
.bmi-calcalutaer [type="text"],
.bmi-calcalutaer [type="time"],
.bmi-calcalutaer [type="url"],
.bmi-calcalutaer [type="week"],
.bmi-calcalutaer input:not([type]),
.bmi-calcalutaer textarea {
  background: #fafafa;
  border: 1px solid #fafafa;
}
.bmi-calcalutaer [type="color"]:focus,
.bmi-calcalutaer [type="date"]:focus,
.bmi-calcalutaer [type="datetime"]:focus,
.bmi-calcalutaer [type="datetime-local"]:focus,
.bmi-calcalutaer [type="email"]:focus,
.bmi-calcalutaer [type="month"]:focus,
.bmi-calcalutaer [type="number"]:focus,
.bmi-calcalutaer [type="password"]:focus,
.bmi-calcalutaer [type="search"]:focus,
.bmi-calcalutaer [type="tel"]:focus,
.bmi-calcalutaer [type="text"]:focus,
.bmi-calcalutaer [type="time"]:focus,
.bmi-calcalutaer [type="url"]:focus,
.bmi-calcalutaer [type="week"]:focus,
.bmi-calcalutaer input:not([type]):focus,
.bmi-calcalutaer textarea:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #90c438;
}
.success-story {
  background-image: url(../public/images/storybg.jpg);
  background-color: #fafafa;
  background-size: cover;
  background-position: center center;
  padding: 50px 0;
}
.success-story.no-bg {
  background-image: none;
  background-color: #fff;
  margin-bottom: 40px;
}
.active-story .owl-item {
  padding: 50px 0;
}
.strong-man img {
  -webkit-box-shadow: 0px 20px 20px 0px rgba(102, 102, 102, 0.15);
  box-shadow: 0px 20px 20px 0px rgba(102, 102, 102, 0.15);
}
.active-story .owl-dots {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: -10px;
  z-index: 999;
}
.active-story .owl-dot {
  background: #ccc;
  height: 8px;
  width: 8px;
  display: inline-block;
  border: 1px solid #ccc;
  -webkit-transition: all 0.19s cubic-bezier(0.47, 0, 0.745, 0.715);
  transition: all 0.19s cubic-bezier(0.47, 0, 0.745, 0.715);
  border-radius: 50%;
  margin: 0 5px;
}
.active-story .owl-dot.active {
  background: #90c438;
  width: 10px;
  height: 10px;
  border: 1px solid #90c438;
}
.ration-price {
  padding: 100px 0px 70px;
}
.rationSinglePrice {
  position: relative;
  border: 1px solid #eee;
  border-radius: 20px;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
  margin-bottom: 30px;
}
.rationSinglePrice .priceHeader {
  position: relative;
}
.rationSinglePrice .priceHeader .price-header-content {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  padding: 40px 0;
  color: #fff;
}
.rationSinglePrice .priceHeader .price-header-content h5 {
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  color: #fff;
}
.rationSinglePrice .priceHeader .price-header-content h3 {
  font-size: 36px;
  font-weight: 700;
  color: #fff;
}
.rationSinglePrice .priceHeader .price-header-content span {
  position: absolute;
  left: 15px;
  bottom: -20px;
  width: 100%;
  text-align: left;
}
.rationSinglePrice .priceBody {
  padding: 15px 0;
}
.rationSinglePrice .priceBody ul {
  margin: 0;
  padding: 0;
  list-style: none;
  width: 65%;
  margin: auto;
}
.rationSinglePrice .priceBody ul li {
  display: block;
  border-bottom: 1px solid #eee;
  padding: 6px 0;
  font-size: 18px;
}
.rationSinglePrice .priceBody ul li:last-child {
  border-bottom: 1px solid transparent;
}
.rationSinglePrice .priceFooter {
  padding: 10px 0 30px;
}
.rationSinglePrice .priceFooter a {
  border: 1px solid #90c438;
  padding: 10px 35px;
  border-radius: 999px;
  color: #90c438;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
path.path-2 {
  fill: #90c438;
  -webkit-transition: all 0.24s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: all 0.24s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
path.path-1 {
  fill: #709c26;
  -webkit-transition: all 0.24s cubic-bezier(0.445, 0.05, 0.55, 0.95);
  transition: all 0.24s cubic-bezier(0.445, 0.05, 0.55, 0.95);
}
.rationSinglePrice.active {
  -webkit-box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.15);
  box-shadow: 0px 0px 40px 0px rgba(90, 90, 90, 0.15);
}
.rationSinglePrice.active path.path-2 {
  fill: #f7941d;
}
.rationSinglePrice.active path.path-1 {
  fill: #b4701e;
}
.rationSinglePrice.active a {
  background: #f7941d;
  border: 1px solid #f7941d;
  color: #fff;
}
.brands-active .owl-item img {
  width: auto;
  display: block;
  margin: auto;
}
.partners-area {
  padding-bottom: 100px;
}
.page-single .partners-area {
  padding: 100px 0;
}
#accordion {
  margin-top: 30px;
}
#accordion .card-header.card-primary {
  background: transparent;
  padding: 0px;
  border: none;
}
#accordion .card {
  margin-bottom: 15px;
  border-color: transparent;
}
#accordion .card:last-child {
  margin-bottom: 0px;
}
.card .card-header.card-primary button[aria-expanded="false"],
.card .card-header.card-primary button[aria-expanded="true"] {
  position: relative;
  display: block;
  text-align: left;
  background: #eee;
  padding: 10px 20px;
  border-radius: 5px;
  font-size: 18px;
  color: #444;
}
.card .card-header.card-primary button[aria-expanded="true"] {
  background: #90c438;
  color: #fff;
  border-radius: 5px 5px 0 0;
}
#accordion .collapse.show .card-body {
  border: 1px solid #90c438;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.card .card-header.card-primary button[aria-expanded="false"]:before {
  position: absolute;
  right: 15px;
  content: "\f107";
  font-family: "Fontawesome";
}
.card .card-header.card-primary button[aria-expanded="true"]:before {
  position: absolute;
  right: 15px;
  content: "\f106";
  font-family: "Fontawesome";
}
.card .card-header.card-primary button {
  display: block;
  width: 100%;
  border: none;
}
.card .card-header.card-primary button:after {
  border-radius: 5px;
}
.card .card-header.card-primary button:hover:after {
  border-radius: 5px;
}
.howitswork {
  padding: 100px 0;
}
.howitsworkgorup {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.singlehowItsWork {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 33.33%;
  flex: 0 0 33.33%;
  max-width: 33%;
  height: 340px;
  background: #fafafa;
  padding: 30px;
  position: relative;
  margin-bottom: 30px;
}
.singlehowItsWork i.howitsicon {
  height: 117px;
  width: 117px;
  background: #90c438;
  line-height: 117px;
  font-size: 65px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  display: block;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.singlehowItsWork h3 {
  font-weight: 700;
  font-size: 28px;
  text-transform: capitalize;
  margin-top: -30px;
}
.singlehowItsWork:nth-child(1) {
  border-bottom: 2px solid #f7941d;
}
.singlehowItsWork:nth-child(1) h3 a {
  color: #f7941d;
}
.singlehowItsWork:nth-child(1) i.howitsicon {
  position: relative;
  top: -80px;
  background: #f7941d;
}
.singlehowItsWork:nth-child(1) .btn-link {
  color: #f7941d;
}
.singlehowItsWork:nth-child(2) {
  height: 400px;
  background: #90c438;
  -webkit-transform: translate(0, -60px);
  transform: translate(0, -60px);
  border-bottom: 2px solid #567521;
}
.singlehowItsWork:nth-child(2) h3 {
  margin-top: 0px;
}
.singlehowItsWork:nth-child(2) h3 a {
  color: #fff;
}
.singlehowItsWork:nth-child(2) p {
  color: #fff;
}
.singlehowItsWork:nth-child(2) .btn-link {
  color: #fff;
}
.singlehowItsWork:nth-child(2) i.howitsicon {
  position: relative;
  color: #90c438;
  margin-bottom: 47px;
  background: #fff;
}
.singlehowItsWork:nth-child(3) {
  border-bottom: 2px solid #ffd200;
}
.singlehowItsWork:nth-child(3) i.howitsicon {
  position: relative;
  top: -80px;
  background: #ffd200;
}
.singlehowItsWork:nth-child(3) h3 a {
  color: #ffd200;
}
.singlehowItsWork:nth-child(3) .btn-link {
  color: #ffd200;
}
.singlehowItsWork:hover i {
  -webkit-transform: rotate(360deg);
  transform: rotate(360deg);
}
.row.grid.howitsGrid[style] {
  height: 340px !important;
}
.page-content {
  padding: 100px 0;
}
.video-promtionOX {
  height: 500px;
  background-image: url("../public/images/vedio-bg.jpg");
  margin-bottom: 40px;
}
.video-promtionOX .video-promoOX {
  position: relative;
  top: 180px;
  -webkit-box-shadow: 0px 25px 30px 0px rgba(44, 44, 44, 0.15);
  box-shadow: 0px 25px 30px 0px rgba(44, 44, 44, 0.15);
}
.videoicon {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: 40px;
  width: 40px;
  background: #90c438;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  color: #fff !important;
  -webkit-box-shadow: 0px 0px 0px 10px rgba(144, 196, 56, 0.3);
  box-shadow: 0px 0px 0px 10px rgba(144, 196, 56, 0.3);
  z-index: 3;
}
.pagination.custom-paganation-1 {
  margin-bottom: 0px;
  margin-left: -0.75rem;
}
.pagination.custom-paganation-1 li.page-item {
  margin: 0 5px;
}
.pagination.custom-paganation-1 li.page-item.active .page-link {
  background: #90c438;
  color: #fff;
  border-color: transparent;
}
.pagination.custom-paganation-1 li.page-item.active .page-link:hover {
  color: #fff;
  background: #90c438;
}
.pagination.custom-paganation-1 li.page-item .page-link {
  border-color: transparent;
  color: #cccccb;
}
.pagination.custom-paganation-1 li.page-item .page-link:hover {
  color: #fff;
  background: #90c438;
}
.pagination.custom-paganation-1 li.page-item .page-link:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
}
.progress {
  background-color: #ccc;
  border-radius: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  height: 4px;
  margin-bottom: 30px;
  position: relative;
}
.progress-bar {
  background-color: #90c438;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.progress-bar.secondary {
  background-color: #f7941d;
}
.progress-bar.secondary-1 {
  background-color: #ffc200;
}
.progress-bar.secondary-2 {
  background-color: #0fa8fd;
}
.progress-title {
  font-size: 16px;
  color: #444;
  text-transform: capitalize;
}
.progress-title span.clr-spn-2 {
  background: #90c438;
  color: #fff;
  height: 30px;
  line-height: 30px;
  margin-top: -16px;
  position: relative;
  text-align: center;
  width: 30px;
  z-index: 1;
  font-size: 12px;
  font-weight: 300;
}
.progress-title span.clr-spn-2::after {
  border-color: transparent transparent #90c438;
  border-style: solid;
  border-width: 0 5px 11px;
  bottom: -5px;
  content: "";
  height: 0;
  left: 12px;
  position: absolute;
  -webkit-transform: rotate(-70deg);
  transform: rotate(-70deg);
  width: 0;
}
.progress-title.secondary span.clr-spn-2 {
  background: #f7941d;
}
.progress-title.secondary span.clr-spn-2::after {
  border-color: transparent transparent #f7941d;
}
.progress-title.secondary-1 span.clr-spn-2 {
  background: #ffc200;
}
.progress-title.secondary-1 span.clr-spn-2::after {
  border-color: transparent transparent #ffc200;
}
.progress-title.secondary-2 span.clr-spn-2 {
  background: #0fa8fd;
}
.progress-title.secondary-2 span.clr-spn-2::after {
  border-color: transparent transparent #0fa8fd;
}
.site-contactform [type="color"],
.site-contactform [type="date"],
.site-contactform [type="datetime"],
.site-contactform [type="datetime-local"],
.site-contactform [type="email"],
.site-contactform [type="month"],
.site-contactform [type="number"],
.site-contactform [type="password"],
.site-contactform [type="search"],
.site-contactform [type="tel"],
.site-contactform [type="text"],
.site-contactform [type="time"],
.site-contactform [type="url"],
.site-contactform [type="week"],
.site-contactform input:not([type]),
.site-contactform textarea {
  height: 46px;
  margin-bottom: 30px;
  border: 1px solid #eeeeee;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.site-contactform [type="color"]:focus,
.site-contactform [type="date"]:focus,
.site-contactform [type="datetime"]:focus,
.site-contactform [type="datetime-local"]:focus,
.site-contactform [type="email"]:focus,
.site-contactform [type="month"]:focus,
.site-contactform [type="number"]:focus,
.site-contactform [type="password"]:focus,
.site-contactform [type="search"]:focus,
.site-contactform [type="tel"]:focus,
.site-contactform [type="text"]:focus,
.site-contactform [type="time"]:focus,
.site-contactform [type="url"]:focus,
.site-contactform [type="week"]:focus,
.site-contactform input:not([type]):focus,
.site-contactform textarea:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 1px solid #90c438;
}
.site-contactform [type="color"]::-webkit-input-placeholder,
.site-contactform [type="date"]::-webkit-input-placeholder,
.site-contactform [type="datetime"]::-webkit-input-placeholder,
.site-contactform [type="datetime-local"]::-webkit-input-placeholder,
.site-contactform [type="email"]::-webkit-input-placeholder,
.site-contactform [type="month"]::-webkit-input-placeholder,
.site-contactform [type="number"]::-webkit-input-placeholder,
.site-contactform [type="password"]::-webkit-input-placeholder,
.site-contactform [type="search"]::-webkit-input-placeholder,
.site-contactform [type="tel"]::-webkit-input-placeholder,
.site-contactform [type="text"]::-webkit-input-placeholder,
.site-contactform [type="time"]::-webkit-input-placeholder,
.site-contactform [type="url"]::-webkit-input-placeholder,
.site-contactform [type="week"]::-webkit-input-placeholder,
.site-contactform input:not([type])::-webkit-input-placeholder,
.site-contactform textarea::-webkit-input-placeholder {
  color: #cccccc;
  font-size: 14px;
  text-transform: capitalize;
}
.site-contactform [type="color"]:-ms-input-placeholder,
.site-contactform [type="date"]:-ms-input-placeholder,
.site-contactform [type="datetime"]:-ms-input-placeholder,
.site-contactform [type="datetime-local"]:-ms-input-placeholder,
.site-contactform [type="email"]:-ms-input-placeholder,
.site-contactform [type="month"]:-ms-input-placeholder,
.site-contactform [type="number"]:-ms-input-placeholder,
.site-contactform [type="password"]:-ms-input-placeholder,
.site-contactform [type="search"]:-ms-input-placeholder,
.site-contactform [type="tel"]:-ms-input-placeholder,
.site-contactform [type="text"]:-ms-input-placeholder,
.site-contactform [type="time"]:-ms-input-placeholder,
.site-contactform [type="url"]:-ms-input-placeholder,
.site-contactform [type="week"]:-ms-input-placeholder,
.site-contactform input:not([type]):-ms-input-placeholder,
.site-contactform textarea:-ms-input-placeholder {
  color: #cccccc;
  font-size: 14px;
  text-transform: capitalize;
}
.site-contactform [type="color"]::-ms-input-placeholder,
.site-contactform [type="date"]::-ms-input-placeholder,
.site-contactform [type="datetime"]::-ms-input-placeholder,
.site-contactform [type="datetime-local"]::-ms-input-placeholder,
.site-contactform [type="email"]::-ms-input-placeholder,
.site-contactform [type="month"]::-ms-input-placeholder,
.site-contactform [type="number"]::-ms-input-placeholder,
.site-contactform [type="password"]::-ms-input-placeholder,
.site-contactform [type="search"]::-ms-input-placeholder,
.site-contactform [type="tel"]::-ms-input-placeholder,
.site-contactform [type="text"]::-ms-input-placeholder,
.site-contactform [type="time"]::-ms-input-placeholder,
.site-contactform [type="url"]::-ms-input-placeholder,
.site-contactform [type="week"]::-ms-input-placeholder,
.site-contactform input:not([type])::-ms-input-placeholder,
.site-contactform textarea::-ms-input-placeholder {
  color: #cccccc;
  font-size: 14px;
  text-transform: capitalize;
}
.site-contactform [type="color"]::placeholder,
.site-contactform [type="date"]::placeholder,
.site-contactform [type="datetime"]::placeholder,
.site-contactform [type="datetime-local"]::placeholder,
.site-contactform [type="email"]::placeholder,
.site-contactform [type="month"]::placeholder,
.site-contactform [type="number"]::placeholder,
.site-contactform [type="password"]::placeholder,
.site-contactform [type="search"]::placeholder,
.site-contactform [type="tel"]::placeholder,
.site-contactform [type="text"]::placeholder,
.site-contactform [type="time"]::placeholder,
.site-contactform [type="url"]::placeholder,
.site-contactform [type="week"]::placeholder,
.site-contactform input:not([type])::placeholder,
.site-contactform textarea::placeholder {
  color: #cccccc;
  font-size: 14px;
  text-transform: capitalize;
}
.site-contactform .form-row {
  margin-left: -15px;
  margin-right: -15px;
}
.site-contactform .form-row > .col,
.site-contactform .form-row > [class*="col-"] {
  padding-left: 15px;
  padding-right: 15px;
}
.site-contactform textarea {
  height: 150px;
  padding-top: 30px;
}
.profile-info,
.single-sidebar {
  border: 1px solid #90c438;
  min-height: 350px;
}
.profile-info .pInfoheader,
.single-sidebar .pInfoheader,
.single-sidebar .sideabrHeader {
  background: #90c438;
  padding: 7px 0;
  text-align: center;
  margin-bottom: 30px;
  position: relative;
}
.profile-info .pInfoheader:after,
.single-sidebar .pInfoheader:after,
.single-sidebar .sideabrHeader:after {
  position: absolute;
  left: 50%;
  bottom: -22px;
  background-image: url("../public/images/Shape.png");
  background-size: contain;
  content: "";
  width: 145px;
  height: 24px;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.profile-info .pInfoheader span,
.single-sidebar .pInfoheader span,
.single-sidebar .sideabrHeader span {
  color: #fff;
  text-transform: capitalize;
  font-size: 20px;
}
.profile-info ul,
.single-sidebar ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.profile-info ul li,
.single-sidebar ul li {
  display: block;
  padding: 8px 35px;
  border-bottom: 1px solid #eee;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.profile-info ul li:last-child,
.single-sidebar ul li:last-child {
  border-bottom: 1px solid transparent;
}
.profile-info ul li:hover,
.single-sidebar ul li:hover {
  color: #90c438;
}
.profile-info ul li.active,
.single-sidebar ul li.active {
  color: #90c438;
}
.profile-info .events-schedules,
.single-sidebar .events-schedules {
  padding-left: 35px;
  border-top: 1px solid #eee;
}
.profile-info .events-schedules h4,
.single-sidebar .events-schedules h4 {
  font-weight: 700;
  margin: 10px 0 5px;
}
.profile-info .events-schedules p,
.single-sidebar .events-schedules p {
  margin: 0 0 0;
  font-size: 14px;
  color: #666;
}
.profile-info .events-schedules p i.fa,
.single-sidebar .events-schedules p i.fa,
.profile-info .events-schedules p i,
.single-sidebar .events-schedules p i {
  padding-right: 4px;
  font-size: 12px;
}
.profile-info .events-schedules p:last-child,
.single-sidebar .events-schedules p:last-child {
  margin: 0 0 15px;
}
.ration-events {
  padding: 100px 0 70px;
}
.singleEvents {
  background: #fafafa;
  margin-bottom: 30px;
}
.singleEvents .eventsThumbs {
  overflow: hidden;
  -webkit-clip-path: polygon(0 0, 100% 0, 100% 82%, 0 100%, 0 50%);
  clip-path: polygon(0 0, 100% 0, 100% 82%, 0 100%, 0 50%);
  position: relative;
  z-index: 1;
}
.singleEvents .eventsThumbs img {
  -webkit-transition: all 0.64s ease-in-out;
  transition: all 0.64s ease-in-out;
}
.singleEvents .eventsThumbs .eventsDtae {
  position: absolute;
  right: 0;
  bottom: 50px;
  width: 108px;
  height: 100px;
  background: #cccccc;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.singleEvents .eventsThumbs .eventsDtae span {
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
  color: #444;
}
.singleEvents .eventsThumbs .eventsDtae span:nth-child(1) {
  display: block;
  font-size: 30px;
  font-weight: 700;
}
.singleEvents .eventsThumbs .eventsDtae span:nth-child(2) {
  display: block;
  font-size: 16px;
  font-weight: 500;
  text-transform: capitalize;
}
.singleEvents .eventsThumbs:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #90c438;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
}
.singleEvents .eventsText {
  padding: 30px 30px 30px;
}
.singleEvents .eventsText h3 {
  text-transform: capitalize;
  font-size: 24px;
  margin: 0 0 15px;
  font-weight: 700;
}
.singleEvents .eventsText h3 a {
  color: #333;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.singleEvents .eventsText ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.singleEvents .eventsText ul li {
  display: block;
  padding: 3px 0;
}
.singleEvents .eventsText ul li i {
  padding-right: 8px;
}
.singleEvents:hover .eventsThumbs img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}
.singleEvents:hover .eventsThumbs:after {
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
  visibility: visible;
  -webkit-animation: zoomIn 0.34s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  animation: zoomIn 0.34s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}
.singleEvents:hover .eventsThumbs .eventsDtae {
  background: #f7941d;
}
.singleEvents:hover .eventsThumbs .eventsDtae span {
  color: #fff;
}
.singleEvents:hover .eventsText h3 a {
  color: #90c438;
}
.site-footer {
  background: #f9f9f9;
  padding: 80px 0 0px;
  position: relative;
  z-index: 1;
}
.site-footer:after {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 168px;
  width: 400px;
  content: "";
  background-image: url("../public/images/footershape.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
}
.single-footer {
  margin-bottom: 30px;
}
.single-footer address strong {
  font-size: 25px;
  color: #555;
}
.single-footer a.footer-logo {
  margin-bottom: 30px;
  display: block;
}
.single-footer ul.post-link {
  margin: 0;
  padding: 0;
  list-style: none;
}
.single-footer ul.post-link li {
  display: block;
}
.single-footer ul.post-link li a {
  display: block;
  text-decoration: underline;
  padding: 3px 0;
  color: #666;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.single-footer ul.post-link li a:hover {
  color: #90c438;
  padding-left: 10px;
}
.single-footer .input-group input[type="email"] {
  height: 52px;
  border-radius: 999px 0 0 999px;
}
.single-footer .input-group input[type="email"]:focus {
  -webkit-box-shadow: none;
  box-shadow: none;
  outline: none;
  border: 1px solid #90c438;
}
.single-footer .input-group input[type="email"]::-webkit-input-placeholder {
  color: #ccc;
  text-transform: capitalize;
}
.single-footer .input-group input[type="email"]:-ms-input-placeholder {
  color: #ccc;
  text-transform: capitalize;
}
.single-footer .input-group input[type="email"]::-ms-input-placeholder {
  color: #ccc;
  text-transform: capitalize;
}
.single-footer .input-group input[type="email"]::placeholder {
  color: #ccc;
  text-transform: capitalize;
}
.single-footer .input-group .btn {
  border-radius: 999px;
}
.single-footer .footer-social {
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;
  margin-top: 30px;
}
.single-footer .footer-social li {
  display: inline-block;
}
.single-footer .footer-social li a {
  display: block;
  height: 35px;
  width: 35px;
  line-height: 35px;
  text-align: center;
  border: 1px solid #ccc;
  color: #ccc;
  border-radius: 50%;
  margin: 0 3px;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.single-footer .footer-social li a:hover {
  border: 1px solid #90c438;
  background: #90c438;
  color: #fff;
}
.footer-titil {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 700;
  margin: 0 0 20px;
}
.footer-decoration {
  height: 100px;
  width: 100px;
  position: absolute;
  right: 100px;
  top: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
}
.footer-decoration .fbox-1,
.footer-decoration .fbox-2 {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.footer-decoration .fbox-1 {
  background: #ffdaae;
}
.footer-decoration .fbox-2 {
  background: #cde3a8;
  margin-left: 10px;
  margin-top: -20px;
}
.site-footer:hover .footer-decoration .fbox-1 {
  background: #cde3a8;
}
.site-footer:hover .footer-decoration .fbox-2 {
  background: #ffdaae;
}
.single-contact-innfo {
  background: #f9f9f9;
  min-height: 286px;
  padding: 0px 30px;
  border-radius: 5px;
  position: relative;
  z-index: 1;
}
.single-contact-innfo:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #90c438;
  z-index: -1;
  opacity: 0;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  visibility: hidden;
}
.single-contact-innfo p {
  text-align: center;
}
.single-contact-innfo .boxicon i {
  font-size: 35px;
  text-align: center;
  display: block;
  line-height: 50px;
  border: 1px dashed #3c3c3c;
  color: #3c3c3c;
  height: 50px;
  width: 50px;
  border-radius: 42px;
  margin: -2px auto;
  position: relative;
  z-index: 2;
  -webkit-transition: all 0.24s ease-in-out;
  transition: all 0.24s ease-in-out;
}
.single-contact-innfo h3 {
  font-size: 24px;
  margin: 20px 0 10px;
  font-weight: 700;
}
.single-contact-innfo a {
  color: #666;
}
.single-contact-innfo:hover:after {
  opacity: 1;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: alpha(opacity=100);
  visibility: visible;
  -webkit-animation: zoomIn 0.24s cubic-bezier(0.05, 0.65, 0, 0.92);
  animation: zoomIn 0.24s cubic-bezier(0.05, 0.65, 0, 0.92);
}
.single-contact-innfo:hover h3,
.single-contact-innfo:hover p,
.single-contact-innfo:hover a {
  color: #fff;
}
.single-contact-innfo:hover .boxicon {
  background: #fff;
}
.single-contact-innfo:hover .boxicon:before {
  border-bottom: 23.09px solid #fff;
}
.single-contact-innfo:hover .boxicon:after {
  border-top: 23.09px solid #fff;
}
.single-contact-innfo:hover .boxicon i {
  color: #90c438;
  border: 1px dashed transparent;
}
.promo-add {
  position: relative;
  z-index: 1;
}
.promo-add:after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  content: "";
  background: #90c438;
  opacity: 0.7;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=70)";
  filter: alpha(opacity=70);
}
.promo-add .promo-text {
  position: absolute;
  left: 50%;
  top: 50%;
  width: 85%;
  height: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: 1px solid #fff;
  z-index: 2;
  color: #fff;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.promo-add .promo-text span {
  font-weight: 300;
  font-size: 14px;
  color: #fff;
}
.promo-add .promo-text h4 {
  font-size: 18px;
  color: #fff;
  font-weight: 500;
}
.promo-add .promo-text h3 {
  font-size: 36px;
  color: #fff;
  font-weight: 700;
}
#googleMap {
  height: 350px;
  width: 100%;
}
@media only screen and (min-width: 1601px) and (max-width: 1900px) {
  .site-slider:before {
    height: 323px;
    width: 700px;
  }
  .slide-content {
    padding-top: 100px;
  }
  .appoinmentshpae-1,
  .appoinmentshpae-2 {
    height: 175px;
    width: 175px;
  }
  .appoinmentshpae-2 {
    height: 40px;
    width: 40px;
  }
  .footer-decoration {
    right: 50px;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1600px) {
  .site-slider:before {
    height: 255px;
    width: 526px;
  }
  .main-menu > ul > li.menu-item-has-children > ul.sub-menu {
    min-width: 180px;
  }
  .main-menu ul ul {
    left: 182px;
  }
  .slide-content {
    padding-top: 100px;
  }
  .appoinmentshpae-1,
  .appoinmentshpae-2 {
    height: 80px;
    width: 80px;
    top: 35%;
  }
  .appoinmentshpae-1 .innershape,
  .appoinmentshpae-2 .innershape,
  .appoinmentshpae-2 .innershape {
    height: 35px;
    width: 35px;
  }
  .appoinmentshpae-2 {
    height: 40px;
    width: 40px;
    top: auto;
  }
  .appoinmentshpae-2 .innershape {
    height: 15px;
    width: 15px;
    bottom: inherit;
  }
  .footer-decoration {
    right: 0;
    top: 30px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .site-slider:before {
    height: 255px;
    width: 526px;
  }
  .site-slider,
  .single-slide,
  .bn_height {
    height: 650px;
  }
  .slide-content {
    padding-top: 150px;
  }
  .custom-tabs1 li.nav-item:nth-child(1) a.nav-link,
  .custom-tabs1 li.nav-item:nth-child(2) a.nav-link {
    border-radius: 999px;
    margin: 3px 0;
  }
  .single-experts {
    height: 415px;
  }
  .appoinmentshpae-1,
  .appoinmentshpae-2 {
    height: 80px;
    width: 80px;
    top: 35%;
  }
  .appoinmentshpae-1 .innershape,
  .appoinmentshpae-2 .innershape,
  .appoinmentshpae-2 .innershape {
    height: 35px;
    width: 35px;
  }
  .appoinmentshpae-2 {
    height: 40px;
    width: 40px;
    top: auto;
  }
  .appoinmentshpae-2 .innershape {
    height: 15px;
    width: 15px;
    bottom: inherit;
  }
  .appoinment-box {
    padding: 50px 0px 70px 50px;
  }
  .frmdesign-1 {
    width: 75%;
  }
  .container-fluid.custom-container {
    padding-left: 4%;
    padding-right: 4%;
  }
  .rationSinglePrice .priceHeader .price-header-content span {
    bottom: 8px;
  }
  .footer-decoration {
    right: 0;
    top: 30px;
  }
  .site-footer:after {
    width: 265px;
  }
  .singleEvents .eventsThumbs .eventsDtae {
    bottom: 34px;
  }
}
@media (max-width: 991.98px) {
  .site-slider:before {
    height: 255px;
    width: 526px;
  }
  .site-slider,
  .single-slide,
  .bn_height {
    height: 650px;
  }
  .slide-content {
    padding-top: 100px;
  }
  .single-health-block-big {
    display: none;
  }
  .health-guide {
    padding: 50px 0 0px;
  }
  .helty-recipe {
    padding: 50px 0 50px;
  }
  .section-padding {
    padding: 50px 0 20px;
  }
  .section-wrapper {
    margin-bottom: 30px;
  }
  .experts-detils {
    margin-top: 50px;
  }
  .appoinment-area {
    margin: 0px 0px 0px;
  }
  .blog-area {
    padding: 50px 0 20px;
  }
  .promotion-area {
    padding: 50px 0;
  }
  .videobgplay {
    top: 0;
    width: 100%;
    position: relative;
  }
  .appoinmentshpae-1,
  .appoinmentshpae-2 {
    height: 80px;
    width: 80px;
    top: 35%;
  }
  .appoinmentshpae-1 .innershape,
  .appoinmentshpae-2 .innershape,
  .appoinmentshpae-2 .innershape {
    height: 35px;
    width: 35px;
  }
  .appoinmentshpae-2 {
    height: 40px;
    width: 40px;
    top: auto;
  }
  .appoinmentshpae-2 .innershape {
    height: 15px;
    width: 15px;
    bottom: inherit;
  }
  .appoinment-box {
    padding: 50px 0px 70px 50px;
    top: 0;
  }
  .frmdesign-1 {
    width: 75%;
  }
  .container-fluid.custom-container {
    padding-left: 2%;
    padding-right: 2%;
  }
  .client-review.section-padding {
    padding: 50px 0;
  }
  .rationSinglePrice .priceHeader .price-header-content span {
    bottom: -12px;
  }
  .success-story {
    padding: 0px 0px 45px;
  }
  .ration-price {
    padding: 50px 0 20px;
  }
  .partners-area {
    padding-bottom: 50px;
  }
  .footer-decoration {
    right: 0;
    top: 30px;
  }
  .site-footer:after {
    width: 265px;
  }
  .site-footer {
    padding: 50px 0 0;
  }
  .howitswork {
    padding: 50px 0;
  }
  .singlehowItsWork {
    padding: 20px;
  }
  .singlehowItsWork h3 {
    font-size: 20px;
    margin-top: -40px;
  }
  .singlehowItsWork:nth-child(2) h3 {
    margin-top: -10px;
  }
  .page-content {
    padding: 50px 0;
  }
  .page-content .experts-detils.noM {
    margin-top: 0px;
  }
  .spacer-extra {
    height: 40px;
  }
  .page-single .partners-area {
    padding: 50px 0;
  }
  .gallerypage .single-gall {
    width: 33.33%;
  }
  .filter-list li {
    padding: 12px 25px;
  }
  .spacer-extra2 {
    height: 40px;
  }
  .single-contact-innfo {
    margin-bottom: 30px;
  }
  .site-contactform {
    margin-bottom: 30px;
  }
  .page-content.minus-padding {
    padding: 50px 0px 20px;
  }
  .singleEvents .eventsThumbs .eventsDtae {
    bottom: 20px;
  }
}
@media (max-width: 767.98px) {
  .home_1 .top-header {
    display: none;
  }
  .site-slider:before {
    width: 100%;
    height: 200px;
  }
  .slide-content h1 {
    font-size: 20px;
    line-height: 30px;
    margin: 0 0 15px;
  }
  .slide-content p {
    font-size: 14px;
    line-height: 24px;
    display: none;
  }
  .slide-content a.btn-mr,
  .slide-content a.btn-link,
  .slide-content a[type="button"],
  .slide-content a[type="reset"],
  .slide-content a[type="submit"],
  .slide-content .single-blog .blog-text a.blog-link,
  .single-blog .blog-text .slide-content a.blog-link,
  .slide-content .call-to-action a,
  .call-to-action .slide-content a,
  .slide-content .rationSinglePrice .priceFooter a,
  .rationSinglePrice .priceFooter .slide-content a {
    margin: 5px 0;
  }
  .site-slider,
  .single-slide,
  .bn_height {
    height: 500px;
  }
  .health-guide .single-health-block:nth-child(1) {
    margin-top: 30px;
  }
  .custom-tabs1 li.nav-item:nth-child(1) a.nav-link,
  .custom-tabs1 li.nav-item:nth-child(2) a.nav-link {
    border-radius: 999px;
    margin: 3px 0;
  }
  .appoinmentshpae-1,
  .appoinmentshpae-2,
  .appoinmentshpae-2 {
    display: none;
  }
  .videobgplay {
    height: 350px;
  }
  .single-blog .blog-text h4 {
    font-size: 20px;
  }
  .call-to-action a {
    margin-top: 30px;
  }
  .site-footer:after {
    height: 32px;
    width: 100px;
  }
  .rationSinglePrice .priceHeader .price-header-content span {
    bottom: -11px;
  }
  .strong-man {
    margin-top: 30px;
  }
  .container-fluid.custom-container {
    padding-left: 18px;
    padding-right: 18px;
  }
  .appoinment-box {
    padding: 30px;
  }
  .frmdesign-1 {
    width: 100%;
  }
  .single-footer address strong {
    font-size: 20px;
  }
  .call-to-action a {
    padding: 15px 20px;
    font-size: 14px;
  }
  .client-minithumb-active {
    margin-top: 15px;
  }
  .slick-dotted.slick-slider {
    margin-bottom: 0px;
  }
  .review_text-active .single-review-text {
    height: auto;
    padding: 15px;
  }
  .review_text-active {
    padding: 40px 0px 0px;
  }
  .single-helathy-recipe.media {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    padding: 15px;
  }
  .call-to-action h3 {
    font-size: 25px;
  }
  .custom-tabs1 li.nav-item a.nav-link {
    font-size: 16px;
  }
  .singlehowItsWork {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
  .singlehowItsWork:nth-child(3) {
    height: auto;
  }
  .row.grid.howitsGrid[style] {
    height: 1030px !important;
  }
  .helty-recipe.home-page2 {
    padding: 50px 0 0;
  }
  .footer-titil {
    font-size: 18px;
  }
  .page-banner h3,
  .video-promtionOX h3 {
    padding-top: 150px;
    font-size: 25px;
  }
  ul.lft li {
    padding-bottom: 5px;
  }
  .single-experts {
    margin-bottom: 30px;
  }
  .spacer-extra {
    height: 15px;
  }
  .footer-decoration {
    display: none;
  }
  #accordion .card-header.card-primary h5 span.btn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .video-promtionOX.mb2 {
    height: auto;
    margin-bottom: 165px;
  }
  .single-blog .blog-thumb img {
    width: 100%;
  }
  .promo-add .promo-text {
    border: none;
  }
  .media.single-comments {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .media.single-comments img {
    margin-bottom: 15px;
  }
  .media.single-comments .media {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .comments-section .media.single-comments h5 span {
    padding-left: 0px;
  }
  .singleEvents .eventsThumbs .eventsDtae {
    bottom: 46px;
  }
  .gallerypage .single-gall {
    width: 100%;
  }
  .filter-list li {
    margin: 5px 0;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  .logo img {
    height: auto;
  }
  .rationSinglePrice .priceHeader .price-header-content span {
    display: none;
  }
  .rationSinglePrice .priceHeader .price-header-content {
    padding: 17px;
  }
  .footer-decoration {
    display: block;
  }
  .gallerypage .single-gall {
    width: 50%;
  }
}

.card .card-header.card-primary button[aria-expanded="false"],
.card .card-header.card-primary button[aria-expanded="true"] {
  font-size: 16px;
}

.card .card-header.card-primary button[aria-expanded="false"],
h4 {
  background-color: #f7f7f7;
}

.ico_header li {
  margin-left: 10px;
  text-decoration: none;
}

ul li a {
  text-decoration: none;
}

.caption {
  font-size: calc(100%-4%);
  font-family: cursive;
}

.navbar-expand-lg .navbar-nav .nav-link {
  font-weight: 600;
}

@media screen and (max-width: 992px) {
  .navbar-nav {
    background-color: #f6e62b;
  }

  .navbar-nav li {
    padding: 2px 12px;
  }

  ul.dropdown-menu.show {
    background-color: #ffffff;
  }
}

/* .carousel-indicators.testimonials{
  justify-content: right;
} */

.carousel-indicators.testimonials button {
  height: 15px !important;
  width: 15px !important;
  top: 30px;
}

li.nav-item,
li.nav-item a {
  color: var(--bs-navbar-active-color);
}

li.nav-item.dropdown-item.active,
li.nav-item .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #70be2b;
}

li.nav-item .dropdown-menu {
  padding: 5px;
  background-color: #f6e62b;
}

li.nav-item a {
  text-decoration: none;
}

@media screen and (max-width: 767px) {
  .logo img {
    width: 55%;
    margin: 0px 10px;
  }

  .top-header {
    display: none;
  }

  .navbar-toggler {
    margin: 0px 10px;
  }
}

.btn-whatsapp {
  background-color: #70be2b;
  position: fixed;
  right: 2%;
  top: 75%;
  z-index: 99999;
  outline: none;
  border: none;
}
